import React, { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { Email } from "../../../assets/Images";
import ScheduleBg from "../../../assets/Images/scheduleBg.png";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../../Services/apis";
import { setLoader } from "../../../Redux/Actions/GeneralActions";
import moment from "moment";

const Schedule = ({ data, setData }) => {
  const [selected, setSelected] = useState(false);
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.userToken);
  const [slots, setSlots] = useState([]);
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const getTodaySloats = async (date) => {
    dispatch(setLoader(true));
    try {
      const res = await ApiCall({
        route: `doctor/timeslots_by_doctor_id/${data?.doctor_id}&${date}'`,
        verb: "get",
        token: token,
      });

      if (res?.status == "200") {
        console.log("response", res?.response);
        setSlots(res?.response?.timeslots);
        dispatch(setLoader(false));
      } else {
        console.log("error", res.response);
        dispatch(setLoader(false));
      }
    } catch (e) {
      console.log("saga login error -- ", e.toString());
    }
  };

  const onChange = (date, dateString) => {
    const a = moment(date).format("YYYY-MM-DD");
    setData({ ...data, appointment_date: a });
    getTodaySloats(a);
    console.log(date, dateString);
  };
  useEffect(() => {
    const a = moment().format("YYYY-MM-DD");
    setData({ ...data, appointment_date: a });
    getTodaySloats(a);
  }, []);

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current.valueOf() <= Date.now();
  }
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  return (
    <>
      <div className="four-step"></div>
      <div className="w-[100%] md:flex justify-between personal-bgg">
        <div className="md:w-[50%] ">
          <div className="md:pl-[120px] pl-[20px] personal mt-[60px]">
            <h1>Schedule</h1>
            {/* <p className="md:pr-[50%] pr-[20px]">
              For safety reasons, the patient must be present to answer personal
              health questions. A parent or legal guardian must be present with
              a minor at all times.
            </p> */}
            {/* <div className="flex mt-[40px] border-b">
              <h6>Email reminders</h6>
              <Email className=" ml-[10px]" />
            </div> */}
          </div>
          {/* <img src={ScheduleBg} className="md:block hidden absolute bottom-0" /> */}
        </div>
        <div className="md:w-[50%] md:pr-[74px] h-[400px] md:pl-[0px] pr-[20px] pl-[20px] mt-[10px]">
          <h1 className="schedule-heading">Select Appointment Date</h1>
          <div className="mb-[10px] w-[100%] ">
            <DatePicker
        inputReadOnly
              onChange={onChange}
              defaultValue={moment()}
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, "day"))
              }
              // disabledTime={disabledDateTime}
              style={{
                marginTop: "10px",
                width: "100%",
                height: "45px",
                borderRadius: "10px",
              }}
              placeholder="Online Appointment Date"
              
            />
          </div>
          <h1 className="schedule-heading">Preferred Time Slot</h1>
          <div className=" mt-[15px]  md:mb-[0px] mb-[10px]">
            <div class="overflow-y-auto h-56">
              {load ? (
                <>
                  {slots?.length > 0 ? (
                    <>
                      {slots.map((item) => {
                        return (
                          <button
                            className={
                              data?.schedule == item
                                ? `selected-time-slot mr-[10px] mb-[10px]`
                                : `time-slot-btn mr-[8px] mb-[10px]`
                            }
                            onClick={() => {
                              setData({ ...data, schedule: item });
                            }}
                          >
                            {moment(item.start_time).format("hh:mm A") + " "} to{" "}
                            {" " + moment(item.end_time).format("hh:mm A")}
                          </button>
                        );
                      })}
                    </>
                  ) : (
                    <center>
                      <p>No timeslots available for the current day</p>
                    </center>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Schedule;
