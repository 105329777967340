import React, { useState, useEffect, useRef } from "react";

const Participant = ({
  participant,
  isAudio = true,
  isVideo = true,
  localUser,
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [videoDisable, setVideoDisable] = useState(false);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      ?.map((publication) => publication.track)
      ?.filter((track) => track !== null);

  useEffect(() => {
    const trackSubscribed = (track) => {
      console.log(track, "trackSubscribed");
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      console.log(track, "trackUnsubscribed");
      if (track.kind === "video") {
        setVideoTracks((videoTracks) =>
          videoTracks?.filter((v) => v !== track)
        );
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) =>
          audioTracks?.filter((a) => a !== track)
        );
      }
    };

    setVideoTracks(trackpubsToTracks(participant?.videoTracks));
    setAudioTracks(trackpubsToTracks(participant?.audioTracks));

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks?.[0];

    if (videoTrack) {
      videoTrack.on("disabled", () => {
        console.log(videoTrack, "videoTrack disabled");
        setVideoDisable(true);
      });
      videoTrack.on("enabled", () => {
        console.log(videoTrack, "videoTrack enabled");
        setVideoDisable(false);
        videoTrack?.attach?.(videoRef?.current);
      });

      const isEnabled = videoTrack?.isEnabled;
      if (isVideo) {
        if (!isEnabled) {
          try {
            videoTrack?.enable?.();
          } catch (error) {
            // console.log(error);
          }
        }
        videoTrack?.attach?.(videoRef?.current);
      } else {
        videoTrack?.disable?.();
      }
      return () => {
        videoTrack?.detach?.();
      };
    }
  }, [videoTracks, isVideo]);

  useEffect(() => {
    const audioTrack = audioTracks?.[0];

    if (audioTrack) {
      audioTrack.on("disabled", () => {
        console.log(audioTrack, "audioTrack disabled");
        audioTrack?.detach?.();
      });
      audioTrack.on("enabled", () => {
        console.log(audioTrack, "audioTrack enabled");
        audioTrack?.attach?.(audioRef?.current);
      });
      if (isAudio) {
        !audioTrack?.isEnabled && audioTrack?.enable?.();
        audioTrack?.attach?.(audioRef?.current);
      } else audioTrack?.disable?.();
      return () => {
        audioTrack?.detach?.();
      };
    }
  }, [audioTracks, isAudio]);

  // only for audio call page
  useEffect(() => {
    const videoTrack = videoTracks?.[0];
    if (videoTrack) {
      try {
        videoTrack?.disable?.();
        videoTrack?.detach?.();
      } catch (error) {
        // console.log(error);
      }
    }
    return () => {};
  }, []);

  // main return
  return (
    <>
      {localUser ? (
        <>
          {isVideo ? (
            <video ref={videoRef} autoPlay={true} />
          ) : (
            <div className="local-user-off-cam">
              <i class={`fa-solid fa-video-slash text-7`}></i>
              <p>Camera turned off.</p>
            </div>
          )}
          <audio ref={audioRef} autoPlay={true} muted={!isAudio} />
        </>
      ) : (
        <>
          {videoDisable && (
            <div className="title">
              Participant have turned off their camera.
            </div>
          )}

          <video
            ref={videoRef}
            autoPlay={true}
            style={{ display: videoDisable ? "none" : "block" }}
          />

          <audio ref={audioRef} autoPlay={true} muted={false} />
        </>
      )}
    </>
  );
};

export default Participant;
