import "./DetailsPage.css";
import { useEffect, useState } from "react";
import { differenceInMinutes, formatDistance } from "date-fns";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PaymentPay from "../Payment/PaymentPay";
import Payment from "../Patient/VideoAppointment/Payment";
import ModalCustom from "../ModalCustom/ModalCustom";
import { useMediaQuery } from "react-responsive";
import { setLoader } from "../../Redux/Actions/GeneralActions";
import { ApiCall } from "../../Services/apis";
import PrimaryButton from "../UI/PrimaryButton";

const DetailsOverview = ({ bookingDetail }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" });
  const [open, setOpen] = useState(false);
  const token = useSelector((state) => state.auth.userToken);
  const user = useSelector((state) => state.auth.userData);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeSteps, setActiveStep] = useState(0);
  const [inPresonStepActive, setInPersonStepActive] = useState(0);
  const [disableVedio, setDisabledVedio] = useState(false);
  const [disableReview, setDisableReview] = useState(false);
  const [bookingType, setBookingType] = useState(false);
  const [prescriptionMessage, setPrescriptionMessage] = useState("");
  console.log("Overview", bookingDetail);

  const complaint_status =
    !bookingDetail?.doctor_id?.email &&
    bookingDetail?.booking_status == "Complaint";

  const handleJoinMeeting = (item, userId) => {
    const date = new Date(item?.schedule?.start_time);
    const currentTime = new Date();
    var timeDifference = differenceInMinutes(date, currentTime);

    const timeDifferenceFormat = formatDistance(date, currentTime, {
      includeSeconds: false,
    });
    if (
      timeDifference <= 5 &&
      timeDifference > -25 &&
      item?.payment_status == true
    ) {
      navigate(`/video-chat?roomId=${item._id}&&name=${userId}`);
    } else if (timeDifference < -90) {
      Swal.fire({
        titleText: `Meeting time is Over`,
        icon: "error",
      });
      return;
    } else {
      Swal.fire({
        titleText: `you can join consultation 
            5 minutes before the starting time
             ${timeDifferenceFormat} remaining
            `,
        icon: "error",
      });
    }
  };

  const requestPrescription = async (e) => {
    e.preventDefault();
    try {
      dispatch(setLoader(true));
      const res = await ApiCall({
        route: `prescription/send_prescription_request/${bookingDetail?._id}`,
        verb: "post",
        token: token,
        params: { prescription_message: "" },
      });
      if (res?.status == "200") {
        dispatch(setLoader(false));
        setPrescriptionMessage("");
        Swal.fire({
          title: "Prescription request sent successfully!",
          text: "Success",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        if (!window?.location?.pathname?.includes("video-chat"))
          navigate(`/messages?selectedChatId=${bookingDetail?.chat_id}`);
      } else {
        console.log("error", res.response);
        Swal.fire(res.response.message, "", "error");
        dispatch(setLoader(false));
      }
    } catch (e) {
      dispatch(setLoader(false));
      console.log("saga login error -- ", e.toString());
    }
  };

  const steps = [
    {
      label: "Booking & Payment",
      description: "",
    },
    {
      label: "Video call with Doctor",
      description: `         `,
    },

    {
      label: "Reviews and Feedback",
      description: ``,
    },
    {
      label: "Prescription",
      description: ``,
    },
    {
      label: "Completed",
      description: ``,
    },
  ];
  const inPersonSteps = [
    {
      label: "Booking Comfirmed",
      description: "",
    },
    {
      label: "In-Person Visit",
      description: `
                  `,
    },
    {
      label: "Reviews and Feedback",
      description: ``,
    },
    {
      label: "Prescription Recieved",
      description: ``,
    },
    {
      label: "Completed",
      description: ``,
    },
  ];
  useEffect(() => {}, [bookingDetail]);

  useEffect(() => {
    if (bookingDetail?.consultation_status === "upcoming" || complaint_status) {
      // console.log("Payment is Done");
      setActiveStep(1);
      setDisableReview(true);
    } else if (
      bookingDetail?.consultation_status === "on_going" ||
      complaint_status
    ) {
      // console.log("Video call is on going");
      setActiveStep(2);
    } else if (
      bookingDetail?.consultation_status === "under_review" &&
      !complaint_status
    ) {
      // console.log("Review received!");
      setActiveStep(3);
      setDisabledVedio(true);
    } else if (
      bookingDetail?.consultation_status === "prescription_send" &&
      !complaint_status
    ) {
      // console.log("Prescription send to pharmacy!");
      setActiveStep(4);
    } else if (
      bookingDetail?.consultation_status === "completed" &&
      !complaint_status
    ) {
      // console.log("Prescription send to pharmacy!");
      setActiveStep(5);
    } else {
      console.log("Overview is not compeleted!");
    }
  }, [bookingDetail]);

  const CheckBookingStatus = () => {
    if (bookingDetail?.booking_type === "visit") {
      setBookingType(true);
      return;
    }
  };

  useEffect(() => {
    CheckBookingStatus();
  }, [bookingDetail]);

  useEffect(() => {
    if (bookingDetail?.consultation_status === "upcoming") {
      setInPersonStepActive(1);
      // setDisableReview(true);
    } else if (bookingDetail?.consultation_status === "on_going") {
      setInPersonStepActive(2);
    } else if (bookingDetail?.consultation_status === "under_review") {
      setInPersonStepActive(3);
      // setDisabledVedio(true);
    } else if (bookingDetail?.consultation_status === "medicine_send") {
      setInPersonStepActive(4);
    } else if (bookingDetail?.consultation_status === "prescription_send") {
      setInPersonStepActive(5);
    } else if (bookingDetail?.consultation_status === "completed") {
      setInPersonStepActive(6);
    } else {
      console.log("Overview is not compeleted!");
    }
  }, [bookingDetail]);

  console.log(complaint_status, "complaint_status");

  return (
    <>
      <div className="bottom-card-section">
        <div className="steppers-container">
          <h3 className="headings">Overview</h3>
          {bookingType ? (
            <Box sx={{ maxWidth: 400, marginLeft: isMobile ? 0 : 4 }}>
              <Stepper activeStep={inPresonStepActive} orientation="vertical">
                {inPersonSteps.map((step) => (
                  <Step expanded={true} key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      <p className="stepper-desc">{step.description}</p>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          ) : (
            <Box sx={{ maxWidth: 400, marginLeft: isMobile ? 0 : 4 }}>
              <Stepper activeStep={activeSteps} orientation="vertical">
                {steps.map((step, index) => (
                  <Step expanded={true} key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      <>
                        <p className="stepper-desc">
                          {index == 1 && complaint_status
                            ? `Your Availability:  ${bookingDetail?.patient_availablity}` ||
                              "The call was unanswered by the doctors, resulting in the patient terminating the consultation prematurely."
                            : step.description}
                        </p>
                        {index == 3 &&
                          bookingDetail?.doctor_id?.email &&
                          bookingDetail?.prescriptions?.filter(
                            (el) => el?.prescription_status
                          )?.length == 0 && (
                            <>
                              <p>
                                Do you need prescription and haven't got it?
                              </p>
                              <form
                                className="prescription-request-form-in-overview"
                                onSubmit={requestPrescription}
                              >
                                {/* <input
                                  name="prescription_message"
                                  placeholder="Request here..."
                                  onChange={(e) =>
                                    setPrescriptionMessage(e.target.value)
                                  }
                                  value={prescriptionMessage}
                                /> */}
                                <PrimaryButton
                                  className={"h-[30px]"}
                                  style={{ fontSize: "12px" }}
                                >
                                  Request Prescription
                                </PrimaryButton>
                              </form>
                            </>
                          )}
                      </>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          )}
        </div>

        {bookingDetail?.booking_type === "visit" ? (
          <>
            <div className="Right-side-content">
              <h5 className="accepted mt-[20%]">Request has been accepted</h5>
              {bookingDetail?.booking_status == "upcoming" ? (
                <h5 className="accepted ">Receptionist has assigned doctor</h5>
              ) : (
                <h5 className="pending ">In-Person visit going on</h5>
              )}
              {bookingDetail?.booking_status == "under_review" ? (
                <div className="accepted"> Doctor checked in person. </div>
              ) : null}
              {bookingDetail?.booking_status == "under_review" ? (
                <span
                  onClick={() => navigate(`/review/${id}`)}
                  className={`${
                    disableReview ? `disable-request-button ` : `request-button`
                  }  `}
                >
                  <p className="text-[#fff] text-[10px] font-bold ">
                    Rate and Review{" "}
                  </p>
                </span>
              ) : (
                <>
                  <div></div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="Right-side-content ">
              {bookingDetail?.payment_status ? (
                <h5 className="accepted ">Payment has been done!</h5>
              ) : bookingDetail?.booking_type == "video_call" ? (
                <ModalCustom
                  ModalName="Payment"
                  ModalTitle="Clear your payment"
                  bookingDetail={bookingDetail}
                />
              ) : bookingDetail?.payment_status ? (
                <h5 className="accepted ">Payment has been done!</h5>
              ) : (
                <h5 className="pending ">Payment is pending!</h5>
              )}

              <div>
                {bookingDetail?.booking_type === "urgent_care" ? (
                  <>
                    {/* {bookingDetail?.payment_status==true  ? 
                   <div
                      className={`${
                        disableVedio || bookingDetail?.booking_status == 'completed'
                          ? `disable-request-button `
                          : `request-button`
                      }  `}
                      onClick={() =>
                     bookingDetail?.booking_type == "video_call" 
                          ? handleJoinMeeting(
                              bookingDetail,
                              bookingDetail?.personal_info?.user_id?._id
                            )
                          : null
                      }
                    >
                      <h3 className="text-[#fff] p-[8px] text-[10px] font-bold  ">
                        <i class="fa-solid fa-video px-1"></i> Start Meeting
                      </h3>
                    </div>:null} */}
                    {!bookingDetail?.doctor_id?.email && !complaint_status ? (
                      <h5 className="accepted ">Awaiting doctor</h5>
                    ) : (
                      complaint_status && (
                        <h5 className="pending ">Doctor will join you soon.</h5>
                      )
                    )}
                  </>
                ) : bookingDetail?.booking_type === "visit" ? (
                  <>
                    <div className="mt-[6%]"> </div>
                  </>
                ) : (
                  <>
                    {bookingDetail?.payment_status == true ? (
                      <div
                        className={`${
                          disableVedio ||
                          bookingDetail?.booking_status == "completed"
                            ? `disable-request-button `
                            : `request-button`
                        }  `}
                        onClick={() =>
                          bookingDetail?.booking_type == "video_call"
                            ? handleJoinMeeting(
                                bookingDetail,
                                bookingDetail?.personal_info?.user_id?._id
                              )
                            : null
                        }
                      >
                        <h3 className="text-[#fff] p-[8px] text-[10px] font-bold  ">
                          <i class="fa-solid fa-video px-1"></i> Start Meeting
                        </h3>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              <div className="">
                {bookingType ? null : (
                  <>
                    {
                      // bookingDetail?.review == null ?
                      bookingDetail?.booking_status == "under_review" ? (
                        <span
                          onClick={() => navigate(`/review/${id}`)}
                          className={`${
                            disableReview
                              ? `disable-request-button `
                              : `request-button`
                          }  `}
                        >
                          <p className="text-[#fff] text-[10px] font-bold ">
                            Rate and Review{" "}
                          </p>
                        </span>
                      ) : (
                        <>
                          <div></div>
                        </>
                      )
                    }
                  </>
                )}
              </div>
              <div className="mt-[14%]">
                {/* <div className="request-button" onClick={() => navigate(`/review/${id}`)}>
            <p className="text-[#fff]"> Request Medications</p>
          </div> */}
                {/* <Modal
            centered
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width={1000}
            className="z-50"
          >
            <PreView
              setMainOpen={setOpen}
              bookingDetail={bookingDetail}
              prescription={bookingDetail?.prescriptions}
            />
          </Modal> */}
              </div>

              <div className="mt-[14%]">
                {/* <div className="request-button" onClick={() => setOpen(!open)}>
            <p className="download-preps ">Send Medicine</p>
          </div> */}
                {/* <Modal
            centered
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width={1000}
            className="z-50"
          > */}
                {/* <PreView
              setMainOpen={setOpen}
              bookingDetail={bookingDetail}
              prescription={bookingDetail?.prescriptions}
            /> */}
                {/* </Modal> */}
              </div>
            </div>
          </>
        )}
      </div>
      {/* <div style={{ margin: "20px", height: "20px" }}></div> */}
    </>
  );
};

export default DetailsOverview;
