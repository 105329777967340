import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../../Services/apis";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  PAYMENT_LOCATION_ID,
  REACT_APP_PAYMENT_APP_ID,
} from "../../../Services/Constants";
import { setLoader } from "../../../Redux/Actions/GeneralActions";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { Modal } from "antd";

const UrgentPaymentModal = ({
  bookingDetails,
  open,
  onCancel,
  onOk,
  getConsultations,
}) => {
  console.log("payemnt", PAYMENT_LOCATION_ID, REACT_APP_PAYMENT_APP_ID);
  const bookingId = bookingDetails?._id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.userToken);

  const tokenRecieved = async (tokenRecieved) => {
    if (parseFloat(bookingDetails?.payment) >= 0) {
      try {
        dispatch(setLoader(true));

        const res = await ApiCall({
          route: `app_api/square_payment`,
          verb: "post",
          params: {
            source_id: tokenRecieved?.token,
            amount: bookingDetails?.payment,
            ...(bookingId && { booking_id: bookingId }),
          },
        });
        if (res?.status == "400") {
          console.log(res?.response, "errror");
          dispatch(setLoader(false));

          if (res?.response?.message == "Invalid booking_id!") {
            Swal.fire({
              title: "Couldn't complete the process!",
              text: "Please try again. Thank you!",
              icon: "error",
              timer: "4000",
            });
            navigate("/prescription/urgent");
            return;
          }

          Swal.fire({
            title: "Payment failed! Please try again.",
            text: "Please pay to confirm your booking. If you face payment error repeatedly, please contact us.",
            icon: "error",
            timer: "40000",
            showConfirmButton: true,
            denyButtonText: "Contact Us",
            denyButtonColor: "#002FA7",
            confirmButtonColor: "#0D0D0D",
            confirmButtonText: "Retry",
            showDenyButton: true,
            icon: "error",
            timer: "20000",
          }).then((result) => {
            if (result.isDenied) {
              window.open(
                "mailto:info@americanclinictokyo.com?subject=Unable%20to%20Pay%20-%20Payment%20Issue&body=Please%20write%20here..",
                "_blank"
              );
            }
          });

          //   const deleteResponse = await ApiCall({
          //     route: `booking/delete_booking/${bookingId}`,
          //     verb: "delete",
          //     token: token,
          //   });
        } else {
          if (res?.status == "200") {
            const res = await ApiCall({
              route: `booking/update_booking/${bookingId}`,
              verb: "put",
              token: token,
              params: { timeslot_id: bookingDetails?.schedule?._id },
            });
            dispatch(setLoader(false));
            getConsultations();

            if (res?.status == "200") {
              const RoomId = bookingId;
              Swal.fire({
                position: "center",
                icon: "success",
                title: "You have successfully booked the consultation.",
                showConfirmButton: true,
                timer: 30000,
              });
              navigate(`/urgent-booking/${RoomId}`);
            } else {
              Swal.fire("Try Again", "", "error");
              dispatch(setLoader(false));
            }
          }
        }
      } catch (e) {
        console.log("saga payment error -- ", e.toString());
        dispatch(setLoader(false));
      }
    }
    dispatch(setLoader(false));
  };

  return (
    <Modal open={open} onCancel={onCancel} onOk={onOk}>
      <div className="my-[10px] w-[100%]  md:px-[30px] pt-[60px] pb-[30px] px-[20px]">
        <table class="payment-page-table-main table-bordered  ">
          <thead>
            <tr>
              <th>Consultation Type</th>
              <th>Condition</th>

              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Urgent Care</td>
              <td>{bookingDetails?.category_id?.category_name}</td>

              <td>{bookingDetails?.payment} ¥</td>
            </tr>
          </tbody>
        </table>
        {/* <Elements stripe={loadStripe(stripeApiKey)}> */}
        <div className="mb-1">
          <PaymentForm
            // applicationId="sandbox-sq0idb-ABA4L7lMgNmf4Vxzk7M8cg"
            applicationId={REACT_APP_PAYMENT_APP_ID}
            locationId={PAYMENT_LOCATION_ID}
            cardTokenizeResponseReceived={tokenRecieved}
          >
            <CreditCard>Pay </CreditCard>
          </PaymentForm>
        </div>
        {/* </Elements> */}
      </div>
    </Modal>
  );
};

export default UrgentPaymentModal;
