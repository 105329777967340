import React, { useState, useEffect } from "react";
import "./index.scss";
import { ApiCall } from "../../Services/apis";
import ChatUsers from "./ChatUsers";
import ChatBox from "./ChatBox";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useFaviconNotification } from "../../helpers/FaviconNotificationContext";
import { useLocation } from "react-router-dom";
import { setLoader } from "../../Redux/Actions/GeneralActions";
import { NoMessagesIcon } from "../../assets/Images";

function Messages() {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" });
  const token = useSelector((state) => state.auth.userToken);
  const loader = useSelector((state) => state.general.loader);

  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedChatId = searchParams.get("selectedChatId");

  const [showMessagesBox, setShowMessagesBox] = useState(false);
  const [selectedChat, setSelectedChat] = useState(
    selectedChatId &&
      selectedChatId !== "null" &&
      selectedChatId !== "undefined"
      ? { _id: selectedChatId }
      : null
  );
  const [allChats, setAllChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);

  const [notifications, setNotifications] = useState([]);
  const [favicon, setFavicon] = useFaviconNotification();

  const getChatList = async () => {
    try {
      dispatch(setLoader(true));
      const res = await ApiCall({
        route: `chat/chat_listing`,
        token: token,
        verb: "get",
      });

      if (res?.status == "200") {
        setAllChats(res?.response?.chat_listing);
        dispatch(setLoader(false));
      } else {
        console.log("Couldn't get chat list");
        dispatch(setLoader(false));
      }
    } catch (e) {
      console.log("Error while getting chat list", e);
      dispatch(setLoader(false));
    }
  };

  const getNotifications = async () => {
    const res = await ApiCall({
      route: `chat/get_notifications`,
      token: token,
      verb: "get",
    });
    if (res?.status === 200) {
      // console.log(res.response, "get all notifications");
      setNotifications(res?.response?.notifications);
    }
  };

  const getChatDetails = async ({ showLoader }) => {
    if (showLoader) setIsMessagesLoading(true);

    try {
      const res = await ApiCall({
        route: `chat/chat_detail/${selectedChat._id}`,
        token: token,
        verb: "get",
      });

      if (res?.status == "200") {
        setMessages(res.response?.chat?.messages);
        setFilteredMessages(res.response?.chat?.messages);
        setIsMessagesLoading(false);
      } else {
        console.log("Couldn't get chat details");
        setIsMessagesLoading(false);
      }
    } catch (e) {
      console.log("Error while getting chat details.", e);
      setIsMessagesLoading(false);
    }
  };

  const markAllAsRead = async () => {
    const res = await ApiCall({
      route: `chat/mark_notification_as_read_by_chat/${selectedChat?._id}`,
      token: token,
      verb: "get",
    });
    if (res.status === 200) {
      setNotifications(res.response.notifications);
      getChatDetails();
    }
  };

  useEffect(() => {
    let count = 0;
    notifications.forEach((notification) => {
      if (!notification?.isRead) {
        count++;
      }
    });
    if (count > 0) {
      setFavicon({ ...favicon, show: true, counter: count });
    } else {
      setFavicon({ ...favicon, show: false, counter: 0 });
    }
  }, [notifications]);

  useEffect(() => {
    getChatList();
    getNotifications();
  }, []);

  const commonProps = {
    setSelectedChat,
    selectedChat,
    setShowMessagesBox,
    allChats,
    setAllChats,
    notifications,
    setNotifications,
    messages,
    setMessages,
    filteredMessages,
    setFilteredMessages,
    isMessagesLoading,
    setIsMessagesLoading,
    getChatDetails,
    getNotifications,
    markAllAsRead,
  };

  return (
    <div className="messages-main-container-component">
      {!loader &&
        (allChats?.length > 0 ? (
          !isMobile ? (
            <>
              <ChatUsers {...commonProps} />
              <ChatBox {...commonProps} />
            </>
          ) : (
            <>
              {!showMessagesBox ? (
                <ChatUsers {...commonProps} />
              ) : (
                <ChatBox {...commonProps} />
              )}
            </>
          )
        ) : (
          <div className="no-messages-in-chat-container">
            <div>
              <NoMessagesIcon />
              <h6>No Messages</h6>
            </div>
          </div>
        ))}
    </div>
  );
}

export default Messages;
