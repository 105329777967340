import Swal from "sweetalert2";
import { differenceInMinutes, formatDistance } from "date-fns";
import API from "../Services/apis/AxiosBase";
import history from "./history";
import defaultProfile from "../assets/Images/user.png";

export const headers = () => {
  const token = localStorage.auth.userToken;
  const bookingToken = localStorage.bookingToken;
  if (token) {
    API.defaults.headers.common["x-sh-auth"] = token;
    if (bookingToken) {
      API.defaults.headers.common["x-booking-token"] = bookingToken;
    }
    return API;
  } else {
    return API.defaults.headers;
  }
};
// ---
export const formatDate = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
// ---
export function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}
// --=
export const profileImageURL = process.env.REACT_APP_IMAGE_URL;

export const getImageUrl = (url) => {
  if (url) {
    return url != "None"
      ? url
        ? profileImageURL + url
        : defaultProfile
      : defaultProfile;
  } else return defaultProfile;
};
export const getImageUrl2 = (url) => {
  if (url) {
    return url != "None"
      ? url
        ?   url
        : defaultProfile
      : defaultProfile;
  } else return defaultProfile;
};

// ---
export function toCapital(str) {
  var firstLetter = str.substr(0, 1);
  return firstLetter.toUpperCase() + str.substr(1);
}
// ---
export const handleJoinMeeting = (item, userId) => {
  const date = new Date(item?.schedule?.start_time);
  const currentTime = new Date();
  var timeDifference = differenceInMinutes(date, currentTime);

  const timeDifferenceFormat = formatDistance(date, currentTime, {
    includeSeconds: false,
  });

  // timeDifference=1;
  if (timeDifference <= 5 && timeDifference > -25 && item?.payment_status ==true) {
    history.push(`/video-chat?roomId=${item._id}&&name=${userId}`);
  } else if (timeDifference < -90) {
    Swal.fire({
      titleText: `Meeting time is Over`,
      icon: "error",
    });
    return;
  } else {
    Swal.fire({
      titleText: `you can join consultation 
          5 minutes before the starting time
           ${timeDifferenceFormat} remaining
          `,
      icon: "error",
    });
  }
};
export const handleJoinUrgentCare = (roomId, userId, contactType) => {
  console.log("room id is this", roomId);
  history.push(
    `/video-chat?roomId=${roomId}&&name=${userId}&&type=urgent&&contactType=${contactType}`
  );
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
