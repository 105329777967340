import React, { useState, useEffect, useRef } from "react";
// import Toggle from '../SideDrawer/Toggle';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

import { Popover, message, notification } from "antd";
import { ApiCall } from "../../../Services/apis";
import Logo from "../../../assets/Images/AmericanClinic Online.svg";
import "./ConsultationNav.css";
import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../Redux/Actions/AuthActions";
import UserDp from "../../../assets/Images/user.png";
// import '../../../components/Details/Details.css'
import notificationIcon from "../../../assets/notification.svg";
import redNotificationIcon from "../../../assets/red-notification.svg";

import socket from "../../../pages/messaging/socketConnection";

import { useFaviconNotification } from "../../../helpers/FaviconNotificationContext";
import { useMediaQuery } from "react-responsive";

const ConsultationNav = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" });

  const [show, setShow] = useState(false);
  const user = useSelector((state) => state.auth.userData);
  const token = useSelector((state) => state.auth.userToken);
  const [favicon, setFavicon] = useFaviconNotification();
  const [transparent, setTransparent] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const location = useLocation();
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const path = location.pathname;
  useEffect(() => {
    console.log(user);
  }, []);
  // console.log("transparent", transparent);

  const scrollFun = () => {
    var scrollValue = window.scrollY;

    if (scrollValue > 20) setTransparent(false);
    else setTransparent(true);
  };

  // check if the path changes then transparent navBar
  useEffect(() => {
    if (path === "/") {
      setTransparent(true);
      document.addEventListener("scroll", scrollFun);
    } else setTransparent(false);

    return () => {
      document.removeEventListener("scroll", scrollFun);
    };
  }, [path]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, true);
    return () => {
      document.removeEventListener("click", handleClickOutSide, true);
    };
  }, [ref]);

  const handleClickOutSide = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };
  const getData = async () => {
    const res = await ApiCall({
      route: `chat/get_notifications`,
      token: token,
      verb: "get",
    });
    if (res.status === 200) {
      // console.log(res.response, "get all notifications");
      setNotifications(res.response.notifications);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteNotification = async (id) => {
    const res = await ApiCall({
      route: `chat/delete_notification/${id}`,
      token: token,
      verb: "get",
    });
    if (res.status === 200) {
      // console.log(res.response, "ge t all notifications");
      setNotifications(notifications.filter((n) => n._id !== id));
    }
  };

  useEffect(() => {
    socket.on("notifications", (notice) => {
      if (user?.user_id !== notice?.senderId) {
        // console.log(notice, "nav notice");
        if (notice.role === "patient") {
          if (notice?.usedFor !== "just_notify") {
            setNotifications(notice.notifications);
          }
        }
      }
    });
    return () => {
      socket.off("notifications");
    };
  }, []);

  useEffect(() => {
    let count = 0;
    notifications.forEach((notification) => {
      if (!notification?.isRead) {
        count++;
      }
    });
    if (count > 0) {
      setFavicon({ ...favicon, show: true, counter: count });
    } else {
      setFavicon({ ...favicon, show: false, counter: 0 });
    }
    setNotificationCount(count);
  }, [notifications]);

  // console.log(favicon);

  if (socket) console.log("socket working");

  const markAsRead = async (id, chatId, notification) => {
    const res = await ApiCall({
      route: `chat/mark_notification_as_read/${id}`,
      token: token,
      verb: "get",
    });
    if (res.status === 200) {
      console.log("marked as read");
      if (notification?.usedFor === "chat" && chatId) {
        navigate(`/messages?selectedChatId=${chatId}`);
      } else if (notification?.usedFor == "booking") {
        navigate(`/patient/details/${notification?.bookingId}`);
      }
    }
  };

  const renderNotifications = () => {
    return (
      <div className="all-notifications-container">
        {notifications.length > 0
          ? notifications.slice(0, 5).map((notification) => (
              <div className="container-single">
                <div
                  className={
                    notification.isRead
                      ? "notification-item"
                      : "notification-item unread-item"
                  }
                  key={notification._id}
                  onClick={async () => {
                    await markAsRead(
                      notification?._id,
                      notification.chatId,
                      notification
                    );
                  }}
                >
                  <span>{notification?.isRead ? "" : "Unread message"}</span>
                  <p>{notification?.notice}</p>
                  {notification?.message && (
                    <i>Message: {notification?.message}</i>
                  )}
                </div>
                <CloseOutlined
                  className="close-icon-notification"
                  onClick={async () => deleteNotification(notification._id)}
                />
              </div>
            ))
          : "No notifications"}
        {notifications.length > 0 && (
          <Link className="see-all-notifications-button" to={"/notifications"}>
            See all
          </Link>
        )}
      </div>
    );
  };

  return (
    <header className="toolbarr ">
      <nav className="toolbar-navigationn  widthcontainer mx-auto">
        <div className="md:hidden block flex">
          <Sidebar />
          <div className="nav-line"></div>
        </div>
        <div className="toolbar-logo ">
          <Link to="/">
            <img
              src={Logo}
              alt="nav-logo"
              className="md:ml-[2px] ml-[40px] "
              style={{ height: "40px" }}
            ></img>
          </Link>
        </div>
        <div className="spacer"></div>
        {user ? (
          <>
            <div className="toolbar-navigation-itemss">
              <ul className="nav-items">
                <li>
                  {" "}
                  <Popover
                    placement="bottom"
                    title={"Notifications"}
                    content={renderNotifications}
                    arrowPointAtCenter={true}
                    trigger={"click"}
                  >
                    <Link>
                      <div className="flex align-center justify-center">
                        <img
                          width={20}
                          height={20}
                          src={
                            notificationCount > 0
                              ? redNotificationIcon
                              : notificationIcon
                          }
                        />
                        <span
                          className={
                            notificationCount > 0
                              ? "pl-1 red-color-notification"
                              : "pl-1"
                          }
                        >
                          <strong style={{ color: "red", fontSize: "15px" }}>
                            {notificationCount > 0 ? notificationCount : ""}
                          </strong>{" "}
                          {!isMobile ? "Notifications" : ""}
                        </span>
                      </div>
                    </Link>
                  </Popover>
                  <div className="modal-show-notifications"></div>
                </li>
                <li>
                  <NavLink to="/profile">
                    <div className="flex align-center justify-center">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 11.5C13.1562 11.5 15.7143 8.92598 15.7143 5.75C15.7143 2.57402 13.1562 0 10 0C6.84375 0 4.28571 2.57402 4.28571 5.75C4.28571 8.92598 6.84375 11.5 10 11.5ZM7.95982 13.6562C3.5625 13.6562 0 17.241 0 21.6658C0 22.4025 0.59375 23 1.32589 23H18.6741C19.4062 23 20 22.4025 20 21.6658C20 17.241 16.4375 13.6562 12.0402 13.6562H7.95982Z"
                          fill="#828282"
                        />
                      </svg>
                      <span className="pl-1"> Profile Settings </span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/messages">
                    <div className="flex justify-center align-center ">
                      <svg
                        width="23"
                        height="20"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 2.875C0 1.28926 1.28926 0 2.875 0H20.125C21.7107 0 23 1.28926 23 2.875V15.8125C23 17.3982 21.7107 18.6875 20.125 18.6875H13.8943L8.3375 22.8563C8.12187 23.018 7.82988 23.0449 7.58281 22.9236C7.33574 22.8023 7.1875 22.5553 7.1875 22.2813V18.6875H2.875C1.28926 18.6875 0 17.3982 0 15.8125V2.875ZM6.82812 6.46875C6.23066 6.46875 5.75 6.94941 5.75 7.54688C5.75 8.14434 6.23066 8.625 6.82812 8.625H16.1719C16.7693 8.625 17.25 8.14434 17.25 7.54688C17.25 6.94941 16.7693 6.46875 16.1719 6.46875H6.82812ZM6.82812 10.7813C6.23066 10.7813 5.75 11.2619 5.75 11.8594C5.75 12.4568 6.23066 12.9375 6.82812 12.9375H11.8594C12.4568 12.9375 12.9375 12.4568 12.9375 11.8594C12.9375 11.2619 12.4568 10.7813 11.8594 10.7813H6.82812Z"
                          fill="#828282"
                        />
                      </svg>
                      <span className="pl-1">Messages</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>

            <div
              className="nav-btn   pl-[0px] ml-[15px] "
              onClick={() => setShow(!show)}
            >
              {notificationCount > 0 && (
                <div className="notification-dot"></div>
              )}
              <img
                src={user.profile_image ? user.profile_image : UserDp}
                alt={user?.first_name}
                height="55px"
                width="55px"
                style={{ borderRadius: "50%", objectFit: "cover" }}
                className="cursor-pointer mr-[10px] h-[55px] w-[55px]"
              />
              {show ? (
                <>
                  <div
                    ref={ref}
                    className="nav-dropdown absolute  cursor-pointer"
                  >
                    <Link to="/my-consultation">
                      <h4 className="text-[#0d0d0d] text-[12px] font-normal cursor-pointer">
                        Dashboard
                      </h4>
                    </Link>

                    <Link to="/messages">
                      <h4 className="text-[#0d0d0d] text-[12px] font-normal pt-[15px] cursor-pointer">
                        Messages
                      </h4>
                    </Link>
                    <Link to="/profile">
                      <h4 className="text-[#0d0d0d] text-[12px] font-normal pt-[15px] cursor-pointer">
                        Profile Settings
                      </h4>
                    </Link>

                    <Link to="/contact-us">
                      <h4 className="text-[#0d0d0d] text-[12px] font-normal pt-[15px] cursor-pointer">
                        Contact Us
                      </h4>
                    </Link>
                    <Link to="/notifications">
                      <h4 className="text-[#0d0d0d] text-[12px] font-normal pt-[15px] cursor-pointer">
                        Notifications
                      </h4>
                    </Link>
                    <h4
                      className="text-[#EB5757] text-[12px] font-bold pt-[15px] cursor-pointer"
                      onClick={() => {
                        {
                          dispatch(logout());
                          navigate("/");
                        }
                      }}
                    >
                      Logout
                    </h4>
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <div className="nav-btn md:block hidden pr-[10px]">
              <Link to="/login">
                <button className="login-btn-nav">Log in</button>
              </Link>
              <Link to="/signup">
                <button className="register-btn-nav">Sign up</button>
              </Link>
            </div>
          </>
        )}
      </nav>
    </header>
  );
};

export default ConsultationNav;
