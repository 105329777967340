import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { ApiCall } from "../../Services/apis";
import API from "../../Services/apis/AxiosBase";

const CountDown = ({ roomName }) => {
  const token = useSelector((state) => state.auth.userToken);
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:01:00");
  const [button, setButton] = useState(false);
  const [tries, setTries] = useState(3);
  const [deadline, setDeadline] = useState(new Date());
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:02:00");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    deadline.setMinutes(new Date().getMinutes() + 2);
    return deadline;
  };

  useEffect(() => {
    if (timer === "00:00:00") {
      setButton(true);
    }
  }, [timer]);
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);
  if (tries > 0)
    return (
      <div
        className="App resendBTn resendBtnChat md:ml-[103px] ml-[0px]"
        // style={{ marginLeft: "103px" }}
      >
        {!button ? (
          <h1 className="time-heading ml-[50px]" style={{ fontSize: "40px" }}>
            {timer}
          </h1>
        ) : (
          <button
            className="btn resend-btn mb-1 w-50 justifyContent-center"
            style={{ paddingLeft: "10px" }}
            onClick={async () => {
              try {
                const res = await ApiCall({
                  route: `booking/resend_urgentcare_mail`,
                  token: token,
                  verb: "get",
                });

                if (res?.status == "200") {
                  console.log("res is there", res?.response);
                  Swal.fire("Success", "", "success");
                } else {
                  console.log("your error is there", res?.response);
                  // Swal.fire("OTP not match ", "Try again", "error");
                }
              } catch (e) {
                console.log("your error is there", e);

                // Swal.fire("invalid OTP  ", "Try again", "error");
              }
              // API.put(`/docprofile/resendUrgentCareRequest/${roomName}`).then(
              //   (res) => {
              //     Swal.fire("Success", "", "success");
              //   }
              // );
              setButton(false);
              setTries(tries - 1);
              clearTimer(getDeadTime());
            }}
          >
            <i class="fa-solid fa-rotate px-2"></i>
            Resend Request
          </button>
        )}
      </div>
    );
  return (
    <div className="mt-[22px] ml-[10%]">
      <h5 className="text-[14px] text-[#fff]">
        Admin is Informed About Request and will contact you shortly!!
      </h5>
      <h5 className="text-[14px] text-[#fff]"> Sorry for inconvenience</h5>
    </div>
  );
};

export default CountDown;
