import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GreyDot,
  ResetFilter,
  VideoEmoticon,
} from "../../../assets/Images/index";
import { ChatIcon } from "../../../assets/Images";
import { handleJoinMeeting } from "../../../helpers/helpers";
import { setLoader } from "../../../Redux/Actions/GeneralActions";
import { ApiCall } from "../../../Services/apis";
import "./MyConsultation.css";
import moment from "moment";
import ConsultationDetails from "../../Modals/ConsultationDetails";
import PrescriptionDetails from "../../Modals/PrescriptionDetails";
import {
  Consultationcard2,
  Consultationcard1,
  Consultationcard3,
} from "../../../assets/Images/index";
import messageIcon from "../../../assets/messageIcon.svg";

import card3 from "../../../assets/Images/card3.png";
import Swal from "sweetalert2";
import { differenceInMinutes, formatDistance } from "date-fns";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import ConsultationNav from "../Nav/ConsultationNav";
import { Select, Space, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import FileIcon from "../../../assets/Images/FileIcon";
import urgentPic from "../../../assets/Urgent.png";
import videoPic from "../../../assets/VideoCall.png";
import inpersonPic from "../../../assets/Inperson.png";
import { sizeWidth } from "@mui/system";
import UrgentPaymentModal from "../UrgentAppointment/UrgentPaymentModal";
// import { url } from "inspector";
const MyConsultation = () => {
  const [consultation, setConsultation] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [allSelect, setAllSelect] = useState("All Appointments");
  const [bookingTypeChange, setBookingTypeChange] = useState("All");
  const token = useSelector((state) => state.auth.userToken);
  const user = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchName, setSearchName] = useState("");
  const [selectedConsultation, setSelectedConsultation] = useState(null);
  // console.log(bookingTypeChange);
  const handleJoinMeeting = (item, userId) => {
    const date = new Date(item?.schedule?.start_time);
    const currentTime = new Date();
    var timeDifference = differenceInMinutes(date, currentTime);

    const timeDifferenceFormat = formatDistance(date, currentTime, {
      includeSeconds: false,
    });

    if (
      timeDifference <= 5 &&
      timeDifference > -25 &&
      item?.payment_status == true
    ) {
      navigate(`/video-chat?roomId=${item._id}&&name=${userId}`);
    } else if (timeDifference < -90) {
      Swal.fire({
        titleText: `Meeting time is Over`,
        icon: "error",
      });
      return;
    } else {
      Swal.fire({
        titleText: `you can join consultation 
            5 minutes before the starting time
             ${timeDifferenceFormat} remaining
            `,
        icon: "error",
      });
    }
  };
  const handleJoinUrgentCare = (roomId, userId, contactType) => {
    // console.log("room id is this", roomId);
    navigate(
      `/video-chat?roomId=${roomId}&&name=${userId}&&type=urgent&&contactType=${contactType}`
    );
  };

  const getConsultations = async (date) => {
    dispatch(setLoader(true));
    try {
      const res = await ApiCall({
        route: `booking/all_patient_bookings`,
        verb: "get",
        token: token,
      });

      if (res?.status == "200") {
        // console.log("response.........", res?.response?.list);
        setConsultation(res?.response?.list);

        dispatch(setLoader(false));
      } else {
        console.log("error", res.response);
        dispatch(setLoader(false));
      }
    } catch (e) {
      console.log("saga login error -- ", e.toString());
    }
  };

  useEffect(() => {
    let searching = consultation?.filter((item) => {
      return searchName?.toLowerCase() === ""
        ? item
        : `${item?.doctor_id?.first_name} ${item?.doctor_id?.last_name} ${item?.personal_info?.user_id?.first_name} ${item?.personal_info?.user_id?.last_name} ${item?.category_id?.category_name}`
            ?.toLowerCase()
            .includes(searchName);
    });
    setFilteredData(searching);
  }, [searchName]);

  useEffect(() => {
    let arr = [];
    if (bookingTypeChange == "All") {
      arr = consultation;
    } else {
      arr = consultation.filter(
        (data) => data && data?.booking_type == bookingTypeChange
      );
    }
    // console.log("arr", arr);
    if (allSelect === "All Appointments") {
      setFilteredData(arr);
    } else {
      let afterfiltertion = arr.filter(
        (item) => item?.booking_status == allSelect
      );
      setFilteredData(afterfiltertion);
    }
  }, [consultation, bookingTypeChange, allSelect]);

  useEffect(() => {
    getConsultations();
  }, []);
  const categories = [
    {
      id: "0",
      name: "Urgent Consultation",
      description: "Talk to a Doctor Now",
      button: "Urgent Consultation",
      background: "red-color",
      picture: (
        <img
          src={urgentPic}
          alt="image"
          style={{ width: "108px", borderRadius: 10 }}
        />
      ),
      link: "prescription",
      type: "urgent",
      disabled: false,
    },

    // {
    //   id: "1",
    //   name: "Video consultation",
    //   description: "Talk to a Doctor Online",
    //   button: "Video Consultation",
    //   background: "black-color",
    //   picture: (
    //     <img
    //       src={videoPic}
    //       alt="image"
    //       style={{ width: "108px", borderRadius: 10 }}
    //     />
    //   ),
    //   link: "prescription",
    //   type: "video",
    //   disabled: true,
    // },
    // {
    //   id: "2",
    //   name: "In-Person Consultation",
    //   description: "Meet your Doctor In-Person",
    //   button: "Schedule Consultation",
    //   background: "blue-color",
    //   picture: (
    //     <img
    //       src={inpersonPic}
    //       alt="image"
    //       style={{ width: "108px", borderRadius: 10 }}
    //     />
    //   ),
    //   link: "prescription",
    //   type: "in-person",
    //   disabled: true,
    // },
  ];

  const handleAllSelect = (value) => {
    setAllSelect(value);
    console.log(`selected `, allSelect);
  };
  let currentTime = new Date().toTimeString();
  // console.log("currentTime",currentTime)
  return (
    <>
      <ConsultationNav />

      <div
        className="mt-[100px]"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "20px",
          // overflow: "auto",
        }}
      >
        <section className="consultation-sec ">
          <div className="upperContainer">
            {/* <p className="pt-[10px] ">Find the best doctor near you</p> */}
            {/* <span className="pt-[10px]">
              1 Million+ patients find doctor from American Clinic Online.
            </span> */}

            {/* </Link> */}
            <div
              className="md:pt-[15px] md:w-[100%] md-pl-[15px] mx-auto md:mx-0
   pt-[0px]"
            >
              <div className="md:flex  md:justify-between ">
                {categories.map((data) => {
                  return (
                    <div
                      disabled={data.disabled}
                      className="md:w-[32%] w-[100%]  "
                      key={data.id}
                    >
                      <div className="category-boxx flex justify-between items-center md:pt-[8px] mt-[20px]   ]">
                        <div className="category-contentt ml-5">
                          <h1 className="headingFontResponsive">{data.name}</h1>
                          <h5>{data.description}</h5>
                          {data?.disabled ? (
                            <button
                              onClick={() =>
                                Swal.fire({
                                  position: "center",
                                  icon: "info",
                                  title: `${data.name} is coming soon!`,
                                  text: `Get ready for the future of healthcare. ${data.name}, bringing care to you soon! 🌐🚀`,
                                  showConfirmButton: false,
                                  timer: 2000,
                                })
                              }
                              className={`category-buttonn   ${data.background}`}
                            >
                              {data.button}
                            </button>
                          ) : (
                            <Link to={`/prescription/${data.type}`}>
                              <button
                                className={`category-buttonn   ${data.background}`}
                              >
                                {data.button}
                              </button>
                            </Link>
                          )}
                        </div>
                        <div className="card-PictureAC">{data.picture}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <h2 className="my_activity">My Activities</h2>
            <div className="md:flex items-center md:w-[100%]  ">
              <div className=" flex items-center">
                <span className=" restFilterIcon">
                  {" "}
                  <ResetFilter />
                </span>
                <Space wrap className="md:pl-[10px] p-[10px] videoFilter">
                  <Select
                    defaultValue="All Consultations"
                    className="w-[150px] md:w-[250px] md:ml-[10px] ml-[10px]"
                    onChange={(value) => setBookingTypeChange(value)}
                    options={[
                      {
                        value: "All",
                        label: "All",
                      },
                      {
                        value: "video_call",
                        label: "Video",
                      },
                      {
                        value: "urgent_care",
                        label: "Urgent Care",
                      },
                      {
                        value: "visit",
                        label: "In Person",
                      },
                    ]}
                  />
                </Space>
                <Space
                  wrap
                  className="md:m-[0px]  md:pl-[10px] m-[10px] ml-[20px] mr-[0px]"
                >
                  <Select
                    defaultValue="All Appointments"
                    className="w-[150px] md:w-[270px] md:ml-[10px] ml-[10px]"
                    onChange={handleAllSelect}
                    options={[
                      {
                        value: "All Appointments",
                        label: "All Appointments",
                      },
                      {
                        value: "upcoming",
                        label: "Upcoming Consultations",
                      },
                      {
                        value: "completed",
                        label: "Completed",
                      },
                      {
                        value: "missed",
                        label: "Missed",
                      },
                    ]}
                  />
                </Space>
              </div>

              <Input
                placeholder="Search... "
                className="w-[356px] flex md:justify-center md:rounded md:ml-[20px] h-[45px] rounded   searchBydoc"
                onChange={(e) => setSearchName(e.target.value)}
                prefix={
                  <SearchOutlined
                    color="#E0E0E0"
                    style={{
                      color: "#E0E0E0",
                      height: "18px",
                      width: "18px",
                      marginRight: "10px",
                      padding: "0px",
                    }}
                  />
                }
                style={{
                  width: "100%",
                }}
              />
            </div>
          </div>
        </section>
        <div className=" w-[86%]  overflow-auto px-[10px] myResponsiveTable">
          <Table dataSource={filteredData} bordered={true}>
            <Column
              title="Type "
              dataIndex="booking_type"
              key="booking_type"
              render={(item) =>
                item == "video_call" ? (
                  <div className="Type">
                    <svg
                      width="25"
                      height="18"
                      viewBox="0 0 25 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.2535 2.28141C23.7938 2.04577 23.2391 2.07687 22.8147 2.3716L18.3529 5.46707C18.0382 5.68457 17.9622 6.11375 18.1819 6.42441C18.3637 6.73555 18.8325 6.80859 19.1493 6.59375L23.6111 3.5V14.4957L19.1493 11.4062C18.8339 11.1894 18.4019 11.2639 18.1823 11.5755C17.9626 11.8863 18.0385 12.3154 18.3532 12.5328L22.815 15.6257C23.0537 15.7909 23.3332 15.8743 23.6128 15.8743C23.8318 15.8743 24.052 15.8226 24.2543 15.7186C24.7135 15.4797 25 14.977 25 14.4613V3.5C25 2.98438 24.7135 2.52031 24.2535 2.28141ZM13.8889 0.75H2.77778C1.24349 0.75 0 1.98105 0 3.5V14.5C0 16.0189 1.24349 17.25 2.77778 17.25H13.8889C15.4232 17.25 16.6667 16.0189 16.6667 14.5V3.5C16.6667 1.98105 15.421 0.75 13.8889 0.75ZM15.2778 14.5C15.2778 15.258 14.6545 15.875 13.8889 15.875H2.77778C2.01215 15.875 1.38889 15.258 1.38889 14.5V3.5C1.38889 2.74203 2.01215 2.125 2.77778 2.125H13.8889C14.6545 2.125 15.2778 2.74203 15.2778 3.5V14.5Z"
                        fill="#002FA7"
                      />
                    </svg>

                    <h3 className="headings-consultation-box">Virtual Call</h3>
                  </div>
                ) : item == "visit" ? (
                  <div className="Type flex justify-center">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.6668 8.25C21.6668 6.73105 20.4731 5.53867 19.0002 5.53867C17.5272 5.53867 16.3335 6.76972 16.3335 8.25C16.3335 9.53004 17.1852 10.5961 18.3335 10.9029V15.125C18.3335 18.1582 15.941 20.5863 13.0002 20.5863C10.0593 20.5863 7.66683 18.1195 7.66683 15.125V15.0555C11.0264 14.7048 13.6668 11.8049 13.6668 8.24927V2.10044C13.6668 1.83996 13.5242 1.60243 13.2983 1.48556L10.6317 0.149229C10.3029 -0.021314 9.90166 0.118347 9.73708 0.456713C9.571 0.720155 9.70433 1.13222 10.0335 1.30281L12.3335 2.48703V8.24914C12.3335 11.2823 9.941 13.7105 7.00016 13.7105C4.05933 13.7105 1.66683 11.2836 1.66683 8.25V2.48703L3.96516 1.30281C4.29458 1.13227 4.42808 0.720155 4.26333 0.380272C4.10016 0.041936 3.696 -0.0977124 3.36683 0.0728306L0.700163 1.40916C0.475996 1.56449 0.333496 1.80211 0.333496 2.06293V8.21176C0.333496 11.767 2.97433 14.6657 6.3335 15.018V15.0875C6.3335 18.8782 9.32433 21.9238 13.0002 21.9238C16.676 21.9238 19.6668 18.8395 19.6668 15.0875V10.9012C20.8168 10.5961 21.6668 9.53047 21.6668 8.25ZM19.0002 9.625C18.2639 9.625 17.6668 9.00926 17.6668 8.28867C17.6668 7.52941 18.2639 6.95234 19.0002 6.95234C19.7364 6.95234 20.3335 7.56808 20.3335 8.28867C20.3335 9.01054 19.7377 9.625 19.0002 9.625Z"
                        fill="#002FA7"
                      />
                    </svg>

                    <h3 className="headings-consultation-box">In Person</h3>
                  </div>
                ) : item == "urgent_care" ? (
                  <div className="Type flex justify-center">
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.25 4.125H16.5V2.0625C16.5 0.925117 15.5762 0 14.4375 0H7.5625C6.42383 0 5.5 0.925117 5.5 2.0625V4.125H2.75C1.23105 4.125 0 5.3582 0 6.875V17.875C0 19.3939 1.23105 20.625 2.75 20.625H19.25C20.7689 20.625 22 19.3939 22 17.875V6.875C22 5.3582 20.7668 4.125 19.25 4.125ZM6.875 2.0625C6.875 1.68309 7.18437 1.375 7.5625 1.375H14.4375C14.8156 1.375 15.125 1.68309 15.125 2.0625V4.125H6.875V2.0625ZM20.625 17.875C20.625 18.633 20.008 19.25 19.25 19.25H2.75C1.99203 19.25 1.375 18.633 1.375 17.875V6.875C1.375 6.11703 1.99203 5.5 2.75 5.5H19.25C20.008 5.5 20.625 6.11703 20.625 6.875V17.875ZM14.4375 11H12.375V8.9375C12.375 8.55937 12.0656 8.25 11.6875 8.25H10.3125C9.93437 8.25 9.625 8.55937 9.625 8.9375V11H7.5625C7.18437 11 6.875 11.3094 6.875 11.6875V13.0625C6.875 13.4406 7.18437 13.75 7.5625 13.75H9.625V15.8125C9.625 16.1906 9.93437 16.5 10.3125 16.5H11.6875C12.0672 16.5 12.375 16.1922 12.375 15.8125V13.75H14.4375C14.8172 13.75 15.125 13.4422 15.125 13.0625V11.6875C15.125 11.3094 14.8156 11 14.4375 11Z"
                        fill="#002FA7"
                      />
                    </svg>

                    <h3 className="headings-consultation-box">Urgent Care</h3>
                  </div>
                ) : null
              }
            />{" "}
            <Column
              title="Date & Time"
              // dataIndex="schedule"
              width={200}
              key="date&time"
              render={(value) => (
                <>
                  {
                    <h3 className="headings-consultation-box">
                      {(value?.booking_type == "video_call"
                        ? new Date(
                            value?.schedule?.appointment_date
                          ).toLocaleDateString() +
                          " , " +
                          new Date(
                            value?.schedule?.start_time
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          }) +
                          " to " +
                          new Date(
                            value?.schedule?.end_time
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : "") ||
                        (value?.booking_type == "visit"
                          ? new Date(
                              value?.schedule?.appointment_date
                            ).toLocaleDateString() +
                            " " +
                            value?.schedule?.batch +
                            " " +
                            (value?.schedule?.timeslot_id
                              ? new Date(
                                  value?.schedule?.start_time
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }) +
                                " to " +
                                new Date(
                                  value?.schedule?.end_time
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })
                              : "")
                          : "") ||
                        (value?.booking_type == "urgent_care"
                          ? new Date(value?.createdAt).toLocaleDateString()
                          : "")}
                    </h3>
                  }
                </>
              )}
            />
            <Column
              title="Doctor"
              dataIndex="doctor_id"
              key="doctor"
              render={(doctor_id) => (
                <h3 className="headings-consultation-box">
                  {doctor_id?.first_name ? (
                    <>{doctor_id?.first_name + " " + doctor_id?.last_name}</>
                  ) : (
                    <>---</>
                  )}
                </h3>
              )}
            />
            <Column
              title="Speciality"
              dataIndex="category_id"
              width={150}
              key="speciality"
              render={(item) => (
                <h3 className="headings-consultation-box">
                  {item?.category_name}
                </h3>
              )}
            />
            <Column
              title="Patient"
              width={150}
              dataIndex="personal_info"
              key="status"
              render={(item) => (
                <h3 className="headings-consultation-box">
                  {" "}
                  {item?.user_id?.first_name} {item?.user_id?.last_name}
                </h3>
              )}
            />
            <Column
              title="Status"
              width={100}
              dataIndex="booking_status"
              key="status"
              render={(item, value) =>
                item == "upcoming" ? (
                  <Tooltip placement="top" title={"Consultation is upcoming"}>
                    <div className="status-inp">
                      {value?.booking_type == "visit" ? (
                        <p className="status-text-inp">Awaiting for visit</p>
                      ) : (
                        <p className="status-text-inp">Awaiting for call</p>
                      )}
                    </div>
                  </Tooltip>
                ) : item == "missed" ? (
                  <Tooltip placement="top" title={"Consultation was missed"}>
                    <div className="missed-status-box">
                      <p className="missed-status-text">Missed</p>
                    </div>
                  </Tooltip>
                ) : item == "completed" ? (
                  <Tooltip placement="top" title={"Consultation is completed."}>
                    <div className="completed-status-box">
                      <p className="completed-status-text">Completed</p>
                    </div>
                  </Tooltip>
                ) : item == "under_review" ? (
                  <Tooltip placement="top" title={"Please, leave a review"}>
                    <div className="review-status-box">
                      <p className="review-status-text">Under Review</p>
                    </div>
                  </Tooltip>
                ) : item == "Complaint" ? (
                  <Tooltip placement="top" title={"Doctor couldn't join."}>
                    <div className="complaint-status-box">
                      <p className="complaint-status-text">Complaint</p>
                    </div>
                  </Tooltip>
                ) : item == "none" && !value?.payment_status ? (
                  <div className="payment-status-box">
                    <p className="payment-status-text">Unpaid</p>
                  </div>
                ) : (
                  <div className="payment-status-box">
                    <p className="payment-status-text">Awaiting Response</p>
                  </div>
                )
              }
            />
            <Column
              title="Action"
              width={100}
              key="action"
              render={(item) =>
                item?.booking_status == "upcoming" ? (
                  <div className="Action">
                    <div
                      onClick={() =>
                        item?.booking_type == "video_call"
                          ? handleJoinMeeting(item, user?.user_id)
                          : handleJoinUrgentCare(
                              item?._id,
                              user?.user_id,
                              "urgent"
                            )
                      }
                    >
                      {item?.booking_type === "visit" ? null : (
                        <div className="mr-[10px] cursor-pointer">
                          {" "}
                          <VideoEmoticon />
                        </div>
                      )}
                    </div>

                    <Link to={`/patient/details/${item?._id}`}>
                      <FileIcon />
                    </Link>
                  </div>
                ) : item?.booking_status == "missed" ? (
                  <div className="Action">
                    <div></div>

                    <Link to={`/patient/details/${item?._id}`}>
                      <FileIcon />
                    </Link>
                  </div>
                ) : item == "completed" ? (
                  <div className="Action">
                    <Link to={`/patient/details/${item?._id}`}>
                      <FileIcon />
                    </Link>
                  </div>
                ) : item == "under_review" ? (
                  <div className="Action">
                    <div></div>

                    <svg
                      width="6"
                      height="22"
                      viewBox="0 0 6 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 15.875C4.44844 15.875 5.625 17.0516 5.625 18.5C5.625 19.9484 4.44844 21.125 3 21.125C1.55156 21.125 0.375 19.9484 0.375 18.5C0.375 17.0516 1.55156 15.875 3 15.875ZM3 8.375C4.44844 8.375 5.625 9.55156 5.625 11C5.625 12.4484 4.44844 13.625 3 13.625C1.55156 13.625 0.375 12.4484 0.375 11C0.375 9.55156 1.55156 8.375 3 8.375ZM5.625 3.5C5.625 4.94844 4.44844 6.125 3 6.125C1.55156 6.125 0.375 4.94844 0.375 3.5C0.375 2.05156 1.55156 0.875 3 0.875C4.44844 0.875 5.625 2.05156 5.625 3.5Z"
                        fill="#828282"
                      />
                    </svg>
                  </div>
                ) : item?.booking_status == "none" ? (
                  <div style={{ justifyContent: "center" }} className="Action">
                    {!item?.payment_status && (
                      <>
                        <div
                          onClick={() => setSelectedConsultation(item?._id)}
                          className="h-10 w-10 bg-blue-800 rounded-full text-white flex justify-center items-center text-sm font-bold mr-[10px] cursor-pointer"
                        >
                          Pay
                        </div>
                        <UrgentPaymentModal
                          bookingDetails={item}
                          open={item?._id === selectedConsultation}
                          onCancel={() => setSelectedConsultation(null)}
                          onOk={() => setSelectedConsultation(null)}
                          getConsultations={getConsultations}
                        />
                      </>
                    )}{" "}
                    <Link to={`/patient/details/${item?._id}`}>
                      <FileIcon />
                    </Link>{" "}
                  </div>
                ) : (
                  <div style={{ justifyContent: "center" }} className="Action">
                    <Link to={`/messages?selectedChatId=${item?.chat_id}`}>
                      <img width={25} height={25} src={messageIcon} />{" "}
                    </Link>
                    <Link to={`/patient/details/${item?._id}`}>
                      <FileIcon />
                    </Link>
                  </div>
                )
              }
            />
          </Table>
        </div>
      </div>
    </>
  );
};

export default MyConsultation;
